
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { copvelAxios } from 'service/api';

const initialState = {
  name: '',
  age: 0,
  userDetail: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null || '',
  collapseSideBar: false,
  collapseDrawer: false,
  selectedLanguage: localStorage.getItem('selected_language') ? localStorage.getItem('selected_language') : 'es',
  isLoggedIn: localStorage.getItem('user_session_token') || false,
  users: [], // Add a users array to store user list data
  loading: false,
  error: null,
  chatHistory: '',
  selectedChat: '',
  chatHistoryLoad: false,
};

// Fetch user details thunk (if needed)
export const fetchUserData = createAsyncThunk('user/fetchUserData', async (_, { rejectWithValue }) => {
  const token = localStorage.getItem('user_auth_token');
  try {


    const orgId = localStorage.getItem('orgId');
    if (orgId) {

      const orgResponse = await copvelAxios.get(`/org/${orgId}/members`);
      return orgResponse.data;
    }
  } catch (error) {

    return rejectWithValue(error.message);

  }
});

// Add user thunk
export const addUser = createAsyncThunk('user/addUser', async (newUserData, { rejectWithValue }) => {
  const token = localStorage.getItem('user_auth_token');
  try {
    const response = await axios.post(`https://kaiapi2.titodi.net/org/{orgId}/add-member`, newUserData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Delete user thunk
export const deleteUser = createAsyncThunk('user/deleteUser', async (userId, { rejectWithValue }) => {
  const token = localStorage.getItem('user_auth_token');
  try {
    await axios.delete(`https://kaiapi2.titodi.net/org/{orgId}/remove-member/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return userId;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setAge: (state, action) => {
      state.age = action.payload;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setCollapseSideBar: (state, action) => {
      state.collapseSideBar = action.payload;
    },
    setCollapseDrawer: (state, action) => {
      state.collapseDrawer = action.payload;
    },
    setChatHistoryLoad: (state, action) => {
      state.chatHistoryLoad = action.payload;
    },
    setChatHistory: (state, action) => {

      state.chatHistory = action.payload;
    },
    setFavoritesChatHistory: (state, action) => {
      state.favoritesChatHistory = action.payload;
    },
    setCurrentChat: (state, action) => {      
      state.currentChat = action.payload;
    },
    setSelectedChat: (state, action) => {
      console.log("playloadd",action?.payload);
      
      state.selectedChat = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    removeChat: (state, action) => {
      state.chatHistory = state.chatHistory.filter(chat => chat.conversation_id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload); // Add the new user to the users array
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter(user => user.id !== action.payload); // Remove the deleted user
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setName, setAge, setCollapseSideBar, setCollapseDrawer, setSelectedLanguage, setIsLoggedIn, setUserDetail, setChatHistory,setChatHistoryLoad, setFavoritesChatHistory,setCurrentChat,setSelectedChat, removeChat } = userSlice.actions;


export default userSlice.reducer;
