import { Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';

const ProtectedRoute = ({ element, onEnter }) => {
  const isAuthenticated = localStorage.getItem('user_session_token');
  
  useEffect(() => {
    if (isAuthenticated && onEnter) {
      onEnter();
    }
  }, [isAuthenticated, onEnter]);

  return isAuthenticated ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
