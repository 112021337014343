import moment from "moment";
import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/en-gb'




export const handleLogout=()=>{
    localStorage.removeItem("user_session_token");
    localStorage.removeItem("user_auth_token");
    setTimeout(() => {
      window.location.href = "/"; 
    }, 500);
}

export const capitalizeFirstLetter = (role) => {
console.log({role})
  return role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();
};


export const setMomentLocale=(language)=> {
  console.log({language})
  moment.locale(language);
}


export 
const formatRelativeTime=(date,language)=> {
  setMomentLocale(language)
  return moment(date).fromNow();
}
export const formatDate=(date,language)=> {
  setMomentLocale(language)
  return moment(date).format("MMM D, YYYY");
}

export const getTextColor = (role) => {
  switch (role) {
      case 'admin':
          return '#026AA2'; // Blue color for admin
      case 'member':
          return '#6941c6'; // Orange color for user
      case 'owner':
          return '#B93815'; // Purple color for developer
      default:
          return '#4B5563'; // Default gray color
  }
};

export const getBorderColor = (role) => {
  switch (role) {
      case 'admin':
          return '#B9E6FE'; // Blue color for admin
      case 'member':
          return '#E9D7FE'; // Orange color for user
      case 'owner':
          return '#F9DBAF'; // Purple color for developer
      default:
          return '#4B5563'; // Default gray color
  }
};

// Helper function for background color
export const getBgColor = (role) => {
  switch (role) {
      case 'admin':
          return '#F0F9FF'; // Light blue for admin
      case 'member':
          return '#F9F5FF'; // Light orange for user
      case 'owner':
          return '#FEF6EE'; // Light purple for developer
      default:
          return '#E5E7EB'; // Default light gray
  }
};


